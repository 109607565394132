import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css'
import axios from 'axios'
import config from '@/../config'
import { VueReCaptcha } from 'vue-recaptcha-v3';
import Jcomponents from "@/assets/jobtimise-common"


(async () => {
  
  Vue.use(Jcomponents)
  Vue.use(VueReCaptcha, { siteKey: config.recaptchaKey  })
  
  axios.defaults.withCredentials = true
  if (store.getters.isAuthenticated) {
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-token')
    await store.dispatch('USER_REQUEST')
  }

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
  
})()