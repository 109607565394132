import Vue from 'vue';
  
const state = {
    display: false
}

const getters = {
    displayPasswordPopup : state => { return state.display }
}
  
const actions = {
    open_password_popup: (context) => {
        context.commit('open_password_popup');
    },
    close_password_popup: context => {
        context.commit('close_password_popup');
    }
}

const mutations = {
    open_password_popup: (state) => {
        Vue.set(state,'display',true)
    },
    close_password_popup: state => {
        Vue.set(state,'display',false)
    }
}
  
export default {
state,
getters,
actions,
mutations
}
  