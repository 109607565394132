<template>
    <v-list-item class="menu-item" @click="cb">
        <v-list-item-icon class="">
            <v-icon large>{{icon}}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title class="text-left" v-text="title"></v-list-item-title>
            <v-list-item-subtitle class="text-left" v-html="subtitle"></v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: 'menu-item',
    props: ['icon', 'title', 'subtitle', 'cb']
}
</script>

<style lang="scss">
    .menu-item{
        box-shadow: 0 0 5px 0 rgba(black, 0.2);
        border-radius: 5px;
        width: 20rem;
        margin-top: 0.5rem;
        .v-list-item__subtitle{
            white-space: normal;
        }
    }
</style>