import Vue from 'vue'
import Vuex from 'vuex'
import messageInput from './modules/messageInput'
import menuCoach from './modules/menuCoach'
import status from '@/assets/jobtimise-common/store/modules/status'
import dialog from '@/assets/jobtimise-common/store/modules/dialog'
import user from "@/assets/jobtimise-common/store/modules/user";
import auth from "@/assets/jobtimise-common/store/modules/auth";
import register from "@/assets/jobtimise-common/store/modules/register";
import passwordPopup from '@/assets/jobtimise-common/store/modules/passwordPopup'
import popup from '@/assets/jobtimise-common/store/modules/popup'
import navigation from '@/assets/jobtimise-common/store/modules/navigation'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    user,
    register,
    messageInput,
    menuCoach,
    status,
    dialog,
    passwordPopup,
    popup,
    navigation
  }
})
