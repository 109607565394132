<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <div :class="['profile',{ hasNotifs: $store.getters.getNotifications.length  }]">
                <v-btn
                    color="transparent"
                    elevation="0"
                    v-bind="attrs"
                    v-on="on"
                    fab
                    small
                    class="ml-2 ml-md-5"
                >
                    <v-icon v-if="$store.getters.getNotifications.length" class="notif-alert" color="error" >mdi-alert-circle</v-icon>
                    <j-profile-bubble class="account" :username="$store.getters.getFirstname" ></j-profile-bubble>
                </v-btn>
            </div>
        </template>
        <div class="white pa-5">
        <v-alert
            class="cursor-pointer" 
            v-for="(notif, index) in $store.getters.getNotifications"
            :key="index"
            dense
            outlined
            :type="notif.type"
            :icon="notif.icon"
            @click="notif.cb"
        ><strong>{{ notif.text }}</strong></v-alert>
        <v-list-item-group>
            <menu-item
                v-for="(item, index) in menuItems"
                :key="index"
                v-bind="item"
                class="wi-sm-100"
            ></menu-item>
        </v-list-item-group>
        </div>
    </v-menu>
</template>

<script>
import MenuItem from "@/components/appBar/MenuItem.vue"

export default {
    name: 'profile-menu',
    components: { MenuItem },
    data() {
        return {
        }
    },
    mounted () {
    },
    computed : {
        menuItems () {
            return  [
                {
                    subtitle: `Mon Compte`,
                    icon: "mdi-account",
                    cb: () => {
                        this.$router.push('/account')
                    }
                },
                {
                    subtitle: `Mes Bilans`,
                    icon: "mdi-file-multiple",
                    cb: () => {
                        this.$router.push('/assessments')
                    }
                }
            ]
        }
    }
}
</script>

<style lang='scss' scoped>

.profile{
    position: relative;
    .notif-alert{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
    &.hasNotifs .account{
        border: $alert solid 3px;
    }
}

</style>