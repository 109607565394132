import Vue from 'vue';
  
const state = {
    display: false,
    items : []
}


const actions = {
    display_menu_coach: (context, payload)=>{
        context.commit('display_menu_coach', payload)
    },
    hide_menu_coach: context=>{
        context.commit('hide_menu_coach')
    }
}

const mutations = {
    display_menu_coach: (state, payload) => {
        Vue.set(state,'display',true)
        Vue.set(state, 'items', payload.items)
    },
    hide_menu_coach: state => {
        Vue.set(state,'display',false)
    }
}

export default {
    state,
    actions,
    mutations
}
  