import Vue from 'vue';
  
const state = {
    display: false,
    content: {
        inputType:'',
        type: '',
        message: '',
        label: '',
        items: '',
        itemText: '',
        itemValue: '',
        inputName: '',
        returnObject: false,
        fileTypes: [],
        usePopup : false,
        optional: false,
        enableOther: false
    },
    cb: ()=>{
        return
    },
    inputValidation: ()=>{
        return true
    }
}


const actions = {
    display_message_input: (context,payload)=>{
        context.commit('display_message_input',payload)
    },
    hide_message_input: context=>{
        context.commit('hide_message_input')
    }
}

const mutations = {
    display_message_input: (state,payload)=>{
        const input ={
            content:{
                inputType: payload.inputType ?? 'input',
                type: payload.type ?? 'text',
                inputName: payload.inputName ?? '',
                placeholder: payload.placeholder ?? '',
                label: payload.label ?? '',
                message: payload.message ?? '',
                items: payload.items ?? [],
                fileTypes: payload.fileTypes ?? [],
                accept: payload.accept ?? '',
                itemValue: payload.itemValue ?? '',
                itemText: payload.itemText ?? '',
                returnObject: payload.returnObject ?? false,
                enableOther: payload.enableOther ?? false
                
            },
            cb: payload.cb ?? '',
            inputValidation: payload.inputValidation ?? '',
            usePopup : payload.usePopup ?? false,
            optional: payload.optional ?? false
        }
        Vue.set(state,'display',true)
        Vue.set(state,'content',input.content)
        Vue.set(state,'cb',input.cb)
        Vue.set(state,'usePopup',input.usePopup)
        Vue.set(state,'optional',input.optional)
        if(input.inputValidation) Vue.set(state, 'inputValidation', input.inputValidation)
        else Vue.set(state, 'inputValidation', () => {return true})
    },
    hide_message_input: state=>{
        Vue.set(state,'display',false)
    }
}

export default {
    state,
    actions,
    mutations
}
  