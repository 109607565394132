<template>
  <v-app-bar color="transparent">
    <v-toolbar-title
      ><router-link class="title-font white--text" to="/"
        >JobEden</router-link
      ></v-toolbar-title
    >
    <coach-menu class="ml-1 ml-md-5"  v-if="$store.state.menuCoach.display"></coach-menu>
    <v-spacer></v-spacer>
    <profile-menu v-if="$store.getters.isAuthenticated"></profile-menu>
    <router-link to="/login" class="d-none d-md-block font-weight-bold white--text mr-10 " v-if="!$store.getters.isAuthenticated" >Connexion</router-link>
    <router-link to="/register" class="d-none d-md-block font-weight-bold white--text mr-10 text-uppercase" v-if="!$store.getters.isAuthenticated" >Inscription</router-link>
    <v-app-bar-nav-icon color="white" @click="$emit('drawer')"></v-app-bar-nav-icon>
  </v-app-bar>
</template>

<script>
import CoachMenu from '@/components/appBar/CoachMenu.vue'
import ProfileMenu from '@/components/appBar/ProfileMenu.vue'

export default {
  name: "app-bar",
  components: { CoachMenu, ProfileMenu }
}
</script>

<style lang="scss">
.v-app-bar {
  box-shadow: none !important;
  .v-toolbar {
    &__content {
      padding-right: 3rem;
      padding-left: 3rem;
      @include phone {
        padding-left: 1rem;
        padding-right: 2rem;
      }
    }
    &__title {
      font-size: 1.5em;
      a {
        color: white;
        text-decoration: none;
      }
    }
  }
  &__nav-icon {
    .mdi-menu::before {
      font-size: 1.5em !important;
    }
  }
  a{
    text-decoration: none;
  }
  .account{
    transform: scale(0.7);
  }
}
</style>