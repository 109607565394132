<template>
    <div class="cardContainer">
        <j-card-infos @validateCard="validateCard" :cta="'Commencer mon coaching'"></j-card-infos>
    </div>
</template>

<script>
import axios from 'axios'
import { apiURL } from '@/../config'
import { loadStripe } from '@stripe/stripe-js'
import { stripePublic } from '@/../config'

export default {
    name: 'register',
    props:['userData','apiRoute'],
    data() {
        return {
            stripe_token: undefined,
            dialogSuccess: false,
            spk: stripePublic
        }
    },
    computed: {
        getUser() {
            return this.userData
        },
        getPriceId() {
            return this.userData.plan.plans.default
        }
    },
    methods: {
        async validateCard(stripe_token) {
            try {
                this.stripe_token = stripe_token
                this.purchase()
            } catch (err) {
              this.$store.dispatch('stop_loading')
              this.$store.dispatch('open_dialog',{title:'Une erreur est survenue',message:`"${err.response.data.message}" Veuillez réessayer.`,button:'Réssayer de remplir les champs'})
            }
        },
        async purchase() {
            try {
                const recaptchaPayIntent = await this.$recaptcha('paymentIntent')
                const recaptchaPayConfirm = await this.$recaptcha('paymentConfirm')
                const recaptchaRegister = await this.$recaptcha('register')
                let trial = new Date()
                trial.setTime(trial.getTime() + 2*24*60*60*1000)
                trial.setHours(0,0,0)
                const intentRes = await axios.post(`${apiURL}public/register-intent/${this.apiRoute}`,{user:this.getUser,priceId:this.getPriceId,trial:trial,stripe_token:this.stripe_token,recaptcha:recaptchaPayIntent})
                    const customerId = intentRes.data.customerId
                    const subId = intentRes.data.subscription.id
                    const clientSecret = intentRes.data.paymentRes.client_secret
                if (intentRes.data.action !== 'done') {
                    const stripe = await loadStripe(this.spk)
                    const secure = await stripe.confirmCardPayment(clientSecret)
                    if (!secure.paymentIntent || secure.paymentIntent.status !== 'succeeded') throw {response: {data: {message:secure.error.message}}}
                    else await axios.post(`${apiURL}public/register-confirm/${this.apiRoute}`,{customerId:customerId,paymentIntent:intentRes.data.paymentRes,recaptcha:recaptchaPayConfirm})
                }
                const registerRes = await axios.post(`${apiURL}public/register`,{user:this.getUser,customerId:customerId,subId:subId,recaptcha:recaptchaRegister})
                this.userData.activationToken = registerRes.data.token
                    this.$emit('done')
            }catch(err) {
                this.$store.dispatch('open_dialog',{title:'Une erreur est survenue',message:`"${err.response.data.message}" Veuillez réessayer.`,button:'Réssayer le paiement'})
            } finally {
                this.$store.dispatch('stop_loading')
            }
        }
    }
}
</script>

<style lang="scss">
.cardContainer {
    width: 70vw;
    @include noPhone {
        width: 30vw;
    }
}
</style>