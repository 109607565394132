<template>
  <v-app>
    <j-pop-up v-if="$store.state.popup.display" v-bind="this.$store.state.popup"></j-pop-up>
    <j-drawer side="right" :contentNavigation="getContentNavigation"  v-model="drawer" @input="drawer = $event"></j-drawer>
    <j-dialog-box v-if="this.$store.state.dialog.display" v-bind="this.$store.state.dialog" ></j-dialog-box>
    <j-loader v-if="this.$store.state.status.loading || this.$store.state.auth.status == 'loading' || this.$store.state.user.status == 'loading' || this.$store.state.register.status == 'loading'"></j-loader>
    <j-password-popup v-if="this.$store.getters.isAuthenticated"></j-password-popup>
    <app-bar @drawer="drawer = !drawer"></app-bar>
    <v-main>
      <v-fade-transition>
        <router-view/>
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from './components/appBar/AppBar.vue';
import axios from 'axios'

export default {
  components: { AppBar },
  name: 'App',
  data: () => {
    return {
      drawer: false
    }
  },
  created () {
    document.title = this.$route.meta.title || 'JOBeden'

    axios.interceptors.response.use(async response => {
      if(response.headers['new-token']){
        await this.$store.dispatch('update_token',response.headers['new-token'])
      }
      return response;
    }, async (error) => {
      if (error.response.status === 401) {
        this.$store.dispatch('reset_pending')
        if (this.$store.getters.isProfileLoaded) await this.$store.dispatch('AUTH_LOGOUT')
        if(!['/','/login','/forgot-password'].includes(this.$router.currentRoute.path)) this.$router.push("/")
      }
      return Promise.reject(error)
    })
  },
  watch: {
    '$route' (to) {
      document.title = to.meta.title || 'JOBeden'
    }
  },
  computed: {
    getContentNavigation () {
      return [
        { icon : 'briefcase', link: '/chat', text: 'Mon premier bilan', isActive: !this.$store.getters.isAuthenticated }
      ]
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import 'scss/style.scss';

.v-application--wrap{
  background: $mainGradient;
}
</style>
