import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next, redir = '/') => {
  if (!store.getters.isAuthenticated) {
    return next()
  }
  next(redir)
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    return next()
  }
  store.dispatch('set_pending', to.fullPath)
  next('/login')
}

function meta ( title = '') {
  const hyphen = title ? ' - ':''
  return{
    title: `${title}${hyphen}JobEden`,
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomeView.vue'),
    beforeEnter: ( to, from, next ) => ifNotAuthenticated(to, from, next, '/dashboard'),
    meta : meta()
  },
  {
    path: '/chat',
    name: 'Chat',
    component: () => import('../views/Chat.vue'),
    alias: '/register',
    beforeEnter: ifNotAuthenticated,
    meta: meta('Premier Bilan')
  },
  {
    path: '/results',
    name: 'Results sample',
    component: () => import('../views/Results.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/results/:assessmentId',
    name: 'Results',
    component: () => import('../views/Results.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Résultats')
  },
  {
    path: '/assessments',
    name: 'Tous mes bilans',
    component: () => import('../views/Assessments.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Vos bilans')
  },
  {
    path: '/dashboard',
    name: 'Tous mes bilans',
    component: () => import('../views/Assessments.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Accueil')
  },
  {
    path: '/invite/:inviteToken',
    name: 'Participation au bilan',
    component: () => import('../views/Invite.vue'),
    meta: meta('Participation au bilan')
  },
  {
    path: '/newChat',
    name: 'Nouveau Bilan',
    component: () => import('../views/NewChat.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Nouveau bilan')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    beforeEnter: ( to, from, next ) => ifNotAuthenticated(to, from, next, '/dashboard'),
    meta : meta('Connexion')
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import('../views/ForgotPassword.vue'),
    meta: meta('Mot de passe oublié')
  },
  {
    path: '/forgot-password/:resetPwdToken',
    name: 'Reset Password',
    component: () => import('../views/ResetPassword.vue'),
    meta: meta('Nouvaeu mot de passe')
  },
  {
    path: '/activate-account/:activationToken',
    name: 'Activate',
    component: () => import('../views/Activate.vue'),
    meta: meta('Activation de votre compte')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../assets/jobtimise-common/views/user/Account.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Votre compte')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach( (to, from, next) => {
  store.dispatch('hide_menu_coach')
  next()
} )

export default router
