<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <div class="coach">
                <v-chip v-if="radiation" class="coach-bubble white inRight ml-2">Je suis là si besoin 😉</v-chip>
                <v-btn
                    color="transparent"
                    elevation="0"
                    v-bind="attrs"
                    v-on="on"
                    fab
                    small
                    @click="radiation = false "
                    :class="['coach-btn ml-2 ml-md-5', { radiation: radiation }]"
                >
                    <j-profile-bubble
                    :username="''"
                    image="/images/coach-sample.png"
                    v-on="on"
                    v-bind="attrs"
                    ></j-profile-bubble>
                </v-btn>
            </div>
        </template>
        <div class="coach-menu white pa-5">
        <v-subheader>Comment puis-je vous aider ?</v-subheader>
        <v-list-item-group>
            <span v-for="(item, index) in $store.state.menuCoach.items" :key="index" class="wi-100">
                <menu-item
                    v-if="Object.keys(item).length"
                    v-bind="item"
                    class="wi-sm-100"
                ></menu-item>
            </span>
        </v-list-item-group>
        </div>
    </v-menu>
</template>

<script>
import MenuItem from "@/components/appBar/MenuItem.vue"

export default {
    name: 'coach-menu',
    components: { MenuItem },
    data() {
        return {
            radiation: true
        }
    },
    mounted () {
        setTimeout( () => {
            this.radiation = false
        }, 5000)
    }
}
</script>

<style lang='scss'>
.coach{
    position: relative;
    &-btn {
        position: relative;
        transform: scale(1.1);
        overflow: visible!important;
        .v-btn__content{
            height: 100%;
        }
        &:after, &:before{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 100%;
            height: 100%;
            width: 100%;
            background: rgba(white, 0.5)!important;
            z-index: -1;
        }
        &.radiation, &:hover{
            &:after, &:before {
                animation : radiation 1.5s ease-in-out infinite; 
            }
            &:after{
                animation-delay : 0.5s; 
            }
        }
        .profile-bubble {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 50%;
            top: 50%;
            transform-origin: center center;
            transform: translate(-50%, -50%);
        }
    }
    &-bubble{
        left: 100%;
        top: 0.3rem;
        max-width: none!important;
        position: absolute!important;
    }
}

</style>